import { combineReducers } from "redux";
import test from "./Test/reducers";
import popUpReducer from "./popUp/reducers";

const rootReducer = combineReducers({
  test,
  popUpReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
