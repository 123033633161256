import useAuth from "@hooks/Auth/useAuth";
import usePopUp from "@hooks/usePopUp";
import { apiOrigin, apiRoute, requestGet, requestPost } from "@lib/api/api";
import { BasicAPIResponseType } from "@typedef/API.types";
import {
  ReportResponseType,
  ReportType,
} from "@typedef/components/Report/report.types";
import React, { useCallback, useEffect, useState } from "react";
import Report from "../Report";
import ReportAnswerContainer from "./ReportAnswerContainer";
import ReportImageContanier from "./ReportImageContainer";

const ReportContainer = () => {
  const threshold = 0.7;
  const { token } = useAuth();
  const { __showPopUpFromHooks } = usePopUp();
  const [reportList, setReportList] = useState<ReportType[]>([]);
  const [selectedReport, setSelectedReport] = useState<ReportType>({
    reportId: 0,
    title: "",
    isChecked: 0,
    createdAt: "",
    reportBy: {
      userId: "",
      displayName: "",
    },
  });
  const [selectedId, setSelectedId] = useState(0);

  // cs 문의 가져오기
  const getReportList = useCallback(async () => {
    const { data } = await requestGet<BasicAPIResponseType<ReportResponseType>>(
      `${apiOrigin}${apiRoute.report}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    const list = data.rows;
    setReportList(list);
  }, [token, reportList]);

  const onReportListEndReached = useCallback(async () => {
    const lastId = reportList[reportList.length - 1].reportId;
    const isChecked = reportList[reportList.length - 1].isChecked;
    const { data } = await requestGet<BasicAPIResponseType<ReportResponseType>>(
      `${apiOrigin}${apiRoute.report}?reportId=${lastId}&isChecked=${isChecked}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    const addList = data.rows;
    setTimeout(() => {
      setReportList([...reportList, ...addList]);
      console.log(lastId);
    }, 1000);
  }, [reportList]);

  // report 클릭
  const onReportClicked = useCallback(
    (index: number) => {
      const selectedItem = reportList[index];
      setSelectedReport(selectedItem);
      console.log(selectedItem);
    },
    [selectedReport]
  );

  // 답변하기
  const onAnswerReport = useCallback(() => {
    if (!selectedReport) return;
    __showPopUpFromHooks(
      <ReportAnswerContainer uid={selectedReport.reportId} />
    );
  }, []);

  useEffect(() => {
    getReportList();
  }, []);

  return (
    <Report
      threshold={threshold}
      onReportListEndReached={onReportListEndReached}
      onReportClicked={onReportClicked}
      reportList={reportList}
      selectedId={selectedId}
      selectedReport={selectedReport}
      onAnswerReport={onAnswerReport}
    />
  );
};

export default ReportContainer;
