import { ReportType } from "@typedef/components/Report/report.types";
import React, { useEffect } from "react";
import "./css/index.css";
import { useInView } from "react-intersection-observer";

type Props = {
  threshold: number;
  onReportListEndReached: () => void;
  onReportClicked: (index: number) => void;
  reportList: ReportType[];
  selectedId: number;
  selectedReport: ReportType;
  onAnswerReport: React.MouseEventHandler<HTMLButtonElement>;
};

const Report = ({
  threshold,
  onReportListEndReached,
  onReportClicked,
  reportList,
  selectedId,
  selectedReport,
  onAnswerReport,
}: Props) => {
  const { ref, inView } = useInView({
    threshold,
  });

  useEffect(() => {
    if (inView && onReportListEndReached) {
      onReportListEndReached();
    }
  }, [inView, onReportListEndReached]);
  return (
    <div className="report-wrap wrap">
      <div className="report-container container">
        <div className="greet">
          안녕하세요, <span>Hellen</span>
        </div>
        <div className="popup-header header">
          <div className="header-left">
            <h2>CS 문의</h2>
            <span>CS 문의 조회 및 CS 문의 답변을 할 수 있습니다.</span>
          </div>
          <div className="header-right"></div>
        </div>

        <div className="content">
          <div className="report-list">
            <ul className="report-list-header list-header">
              <li className="sort-number">순번</li>
              <li className="sort-nickname">닉네임</li>
              <li className="sort-date">날짜</li>
              <li className="sort-answer-state">답변</li>
            </ul>

            <ul className="report-list-box list-content">
              {reportList.map((item, index) => {
                return (
                  <li
                    className={`popup-list-item list-item ${
                      item.reportId === selectedId ? "selected" : ""
                    }`}
                    key={index}
                    ref={
                      index >= Math.floor(reportList.length * threshold)
                        ? ref
                        : undefined
                    }
                  >
                    <ul
                      key={index}
                      className="report-item-info"
                      onClick={() => onReportClicked(index)}
                    >
                      <li>{index + 1}</li>
                      <li>{item.reportBy.displayName}</li>
                      <li>{item.createdAt}</li>
                      <li>{item.isChecked ? "완료" : "미완"}</li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="details-wrap">
            {selectedReport && (
              <div className="item-details">
                <div className="option">
                  <label htmlFor="item-question">건의 및 문의 내용</label>
                  <div className="item-question" id="item-question">
                    {selectedReport.title}
                  </div>
                </div>
                {/* <div className="option">
              <label htmlFor="item-detail-content">상세내용</label>
              <div className="item-detail-content" id="item-detail-content">
                {selectedReport.content}
              </div>
            </div> */}

                <button
                  className="answer-button"
                  disabled={selectedReport.isChecked !== 0 || !selectedReport}
                  onClick={onAnswerReport}
                >
                  답변하기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
