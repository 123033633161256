import usePopUp from "@hooks/usePopUp";
import { PopupFileType } from "@typedef/components/Popup/Popup.types";
import React, { useCallback } from "react";
import PopupImage from "../components/PopupImage";

type Props = {
  image: PopupFileType;
};

const PopupImageContanier = ({ image }: Props) => {
  const { __hidePopUpFromHooks } = usePopUp();

  const onCloseImage = useCallback(() => {
    __hidePopUpFromHooks();
  }, [__hidePopUpFromHooks]);

  return <PopupImage onCloseImage={onCloseImage} image={image} />;
};

export default PopupImageContanier;
