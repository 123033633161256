import React, { useCallback, useEffect, useState } from "react";
import MainBanner from "../MainBanner";
import { apiOrigin, apiRoute, requestDelete, requestGet } from "@lib/api/api";
import {
  MainbannerResponseType,
  MainBannerType,
} from "@typedef/components/MainBanner/MainBanner.types";
import { BasicAPIResponseType } from "@typedef/API.types";
import useAuth from "@hooks/Auth/useAuth";
import { useNavigate } from "react-router-dom";

const MainBannerContainer = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(0);
  const [mainBannerList, setMainBannerList] = useState<MainBannerType[]>([]);

  // 배너 리스트 가져오기
  const getMainBannerList = useCallback(async () => {
    const { data } = await requestGet<
      BasicAPIResponseType<MainbannerResponseType>
    >(`${apiOrigin}${apiRoute.bannerList}`, {
      Authorization: `Bearer ${token}`,
    });
    setMainBannerList(data["rows"]);
  }, [mainBannerList]);

  // viewCount 증가
  const onUrlClicked = useCallback(async (bannerId: number) => {
    const { data } = await requestGet<BasicAPIResponseType<"">>(
      `${apiOrigin}${apiRoute.banner}/${bannerId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  }, []);

  // 배너 삭제
  const onRemoveBanner = useCallback(
    async (bannerId: number) => {
      const { data, status } = await requestDelete<BasicAPIResponseType<any>>(
        `${apiOrigin}${apiRoute.banner}/${bannerId}`,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      const updatedList = [
        ...mainBannerList.filter((item) => item.bannerId !== bannerId),
      ];
      setMainBannerList(updatedList);
    },
    [mainBannerList]
  );

  // 배너 선택
  const onSelectBanner = useCallback(
    (bannerId: number) => {
      setSelectedId(bannerId);
      console.log(bannerId);
    },
    [selectedId]
  );

  // 배너 수정
  const onEditBanner = useCallback(() => {
    const editItem = mainBannerList.filter(
      (item) => item.bannerId === selectedId
    )[0];
    navigate(`/mainbanner/edit/${selectedId}`, { state: editItem });
  }, [selectedId, mainBannerList]);

  useEffect(() => {
    getMainBannerList();
  }, []);

  return (
    <MainBanner
      selectedId={selectedId}
      mainBannerList={mainBannerList}
      onUrlClicked={onUrlClicked}
      onRemoveBanner={onRemoveBanner}
      onSelectBanner={onSelectBanner}
      onEditBanner={onEditBanner}
    />
  );
};

export default MainBannerContainer;
