import React from "react";
import { Link } from "react-router-dom";
import { NavigationButtonData } from "@typedef/components/Navigation/Navigation.types";

const NavigationButton = ({ name, link, buttonName }: NavigationButtonData) => {
  return (
    <button name={name} className="button-content">
      <Link className="navigation-link" to={link}>
        {buttonName}
      </Link>
    </button>
  );
};

export default NavigationButton;
