import useAuth from "@hooks/Auth/useAuth";
import { apiOrigin, apiRoute, requestFormPost } from "@lib/api/api";
import { BasicAPIResponseType } from "@typedef/API.types";
import { MainBannerCreateResponseType } from "@typedef/components/MainBanner/MainBannerCreate.types";
import React, { useCallback, useEffect, useState } from "react";
import MainBannerCreate from "../MainBannerCreate";

const MainBannerCreateContainer = () => {
  const { token } = useAuth();
  const [title, setTitle] = useState<string>("");
  const [popupFile, setPopupFile] = useState<File | null>(null);
  const [exposureFrom, setExposureFrom] = useState<number>(0);
  const [exposureTo, setExposureTo] = useState<number>(0);
  const [sort, setSort] = useState<number>(0);
  const [referenceUrl, setReferenceUrl] = useState<string>("");

  // 배너 저장하기
  const onCreateBanner = useCallback(async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("exposureFrom", exposureFrom.toString());
    formData.append("exposureTo", exposureTo.toString());
    formData.append("sort", sort.toString());
    formData.append("referenceUrl", referenceUrl);
    if (popupFile) {
      formData.append("bannerFile", popupFile);
    }

    const { data } = await requestFormPost<
      BasicAPIResponseType<MainBannerCreateResponseType>
    >(
      `${apiOrigin}${apiRoute.banner}`,
      {
        Authorization: `Bearer ${token}`,
      },
      formData
    );
  }, [title, exposureFrom, exposureTo, sort, referenceUrl]);

  return (
    <MainBannerCreate
      onCreateBanner={onCreateBanner}
      popupFile={popupFile}
      setTitle={setTitle}
      setPopupFile={setPopupFile}
      setExposureFrom={setExposureFrom}
      setExposureTo={setExposureTo}
      setSort={setSort}
      setReferenceUrl={setReferenceUrl}
    />
  );
};

export default MainBannerCreateContainer;
