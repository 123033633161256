import { PopupType } from "@typedef/components/Popup/Popup.types";
import React, { useEffect } from "react";
import "./css/index.css";
import { useInView } from "react-intersection-observer";

type Props = {
  selectedId: number;
  popupList: PopupType[];
  onPopupClicked: (index: number) => void;
  selectedPopup: PopupType;
  threshold: number;
  onPopupListEndReached: () => void;
  onFloatImage: React.MouseEventHandler<HTMLButtonElement>;
  onUrlClicked: React.MouseEventHandler<HTMLAnchorElement>;
  onCanclePopup: React.MouseEventHandler<HTMLButtonElement>;
};

const Popup = ({
  selectedId,
  popupList,
  onPopupClicked,
  selectedPopup,
  threshold,
  onPopupListEndReached,
  onFloatImage,
  onUrlClicked,
  onCanclePopup,
}: Props) => {
  const { ref, inView } = useInView({
    threshold,
  });

  useEffect(() => {
    if (inView && onPopupListEndReached) {
      onPopupListEndReached();
    }
  }, [inView, onPopupListEndReached]);

  return (
    <div className="popup-wrap wrap wrap">
      <div className="popup-container container">
        <div className="greet">
          안녕하세요, <span>Hellen</span>
        </div>
        <div className="popup-header header">
          <div className="header-left">
            <h2>팝업 내역</h2>
            <span>팝업 내역 조회 및 팝업 취소를 할 수 있습니다.</span>
          </div>
          <div className="header-right"></div>
        </div>

        <div className="content">
          <div className="popup-list">
            <ul className="popup-list-header list-header">
              <li className="sort-number">순번</li>
              <li className="sort-title">팝업명</li>
              <li className="sort-click">클릭수</li>
              <li className="sort-status">상태</li>
            </ul>
            <ul className="popup-list-content list-content">
              {popupList.map((item, index) => {
                return (
                  <li
                    className={`popup-list-item list-item ${
                      item.popupId === selectedId ? "selected" : ""
                    }`}
                    key={index}
                    onClick={() => onPopupClicked(index)}
                    ref={
                      index >= Math.floor(popupList.length * threshold)
                        ? ref
                        : undefined
                    }
                  >
                    <ul>
                      <li>{index + 1}</li>
                      <li>{item.title}</li>
                      <li>{item.viewCount}</li>
                      <li>
                        {Date.parse(item.exposureTo) < new Date().getTime()
                          ? "만료"
                          : "진행"}
                      </li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="popup-list-item-details-wrap">
            {selectedPopup && (
              <div className="popup-list-item-details list-item-detail">
                <div className="option">
                  <label htmlFor="title">팝업 명</label>
                  <div className="item-title" id="title">
                    {selectedPopup.title}
                  </div>
                </div>
                <div className="option">
                  <label htmlFor="image">팝업 이미지</label>
                  <button className="show-image-button" onClick={onFloatImage}>
                    이미지 보기
                  </button>
                </div>
                <div className="option">
                  <label htmlFor="reference-url">레퍼런스 URL</label>
                  <div className="reference-url" id="reference-url">
                    <a
                      href={`https://${selectedPopup.referenceUrl}`}
                      onClick={onUrlClicked}
                      target="_blank"
                    >
                      {selectedPopup.referenceUrl}
                    </a>
                  </div>
                </div>
                <div className="option">
                  <label htmlFor="post-date">노출 기간</label>
                  <div className="date" id="post-date">
                    {`${selectedPopup.exposureFrom.substring(
                      0,
                      10
                    )} ~ ${selectedPopup.exposureTo.substring(0, 10)}`}
                  </div>
                </div>
                <div className="option">
                  <div className="click-number">
                    팝업 클릭 수 : {selectedPopup.viewCount}
                  </div>
                </div>
                <button
                  className="delete-button"
                  onClick={onCanclePopup}
                  disabled={
                    selectedPopup.popupId === 0 ||
                    Date.parse(selectedPopup.exposureTo) < new Date().getTime()
                  }
                >
                  팝업 취소
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
