import useAuth from "@hooks/Auth/useAuth";
import { apiOrigin, apiRoute, requestFormPatch } from "@lib/api/api";
import { BasicAPIResponseType } from "@typedef/API.types";
import { MainBannerType } from "@typedef/components/MainBanner/MainBanner.types";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainBannerEdit from "../MainBannerEdit";

const MainBannerEditContainer = () => {
  const state = useLocation();
  const { bannerId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();

  const [banner, setBanner] = useState<MainBannerType>(
    state.state as MainBannerType
  );
  const [title, setTitle] = useState(banner.title);
  const [exposureFrom, setExposureFrom] = useState(
    banner.exposureFrom.substring(0, 16)
  );
  const [exposureTo, setExposureTo] = useState(
    banner.exposureTo.substring(0, 16)
  );
  const [sort, setSort] = useState(banner.sort);
  const [referenceUrl, setReferenceUrl] = useState(banner.referenceUrl);
  const [image, setImage] = useState(banner.bannerFile);
  const [updatedFile, setUpdatedFile] = useState<File | null>(null);

  const onStoreBanner = useCallback(async () => {
    const formData = new FormData();

    if (title !== banner.title) {
      formData.append("title", title);
    }
    if (exposureFrom !== banner.exposureFrom.substring(0, 16)) {
      formData.append("exposureFrom", Date.parse(exposureFrom).toString());
    }
    if (exposureTo !== banner.exposureTo.substring(0, 16)) {
      formData.append("exposureTo", Date.parse(exposureTo).toString());
    }
    if (sort !== banner.sort) {
      formData.append("sort", sort.toString());
    }
    if (referenceUrl !== banner.referenceUrl) {
      formData.append("referenceUrl", referenceUrl);
    }
    if (updatedFile) {
      formData.append("bannerFile", updatedFile);
    }

    const data = requestFormPatch<BasicAPIResponseType<any>>(
      `${apiOrigin}${apiRoute.banner}/${bannerId}`,
      {
        Authorization: `Bearer ${token}`,
      },
      formData
    );

    navigate("/mainbanner/list");
  }, [title, exposureFrom, exposureTo, sort, referenceUrl, updatedFile]);

  useEffect(() => {}, []);
  return (
    <MainBannerEdit
      title={title}
      exposureFrom={exposureFrom}
      exposureTo={exposureTo}
      sort={sort}
      referenceUrl={referenceUrl}
      image={image}
      onStoreBanner={onStoreBanner}
      updatedFile={updatedFile}
      setTitle={setTitle}
      setUpdatedFile={setUpdatedFile}
      setExposureFrom={setExposureFrom}
      setExposureTo={setExposureTo}
      setSort={setSort}
      setReferenceUrl={setReferenceUrl}
    />
  );
};

export default MainBannerEditContainer;
