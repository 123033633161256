import React from "react";
import NavigationButton from "./components/NavigationButton";
import { NavigationButtonData } from "@typedef/components/Navigation/Navigation.types";
import "./css/index.css";

type Props = {
  NavigationButtonDataList: NavigationButtonData[];
};

const Navigation = ({ NavigationButtonDataList }: Props) => {
  return (
    <div className="navigation-wrap">
      <ul className="button-list">
        {NavigationButtonDataList.map((NavigationButtonDataItem) => (
          <li className="navigaion-button" key={NavigationButtonDataItem.name}>
            <NavigationButton
              name={NavigationButtonDataItem.name}
              link={NavigationButtonDataItem.link}
              buttonName={NavigationButtonDataItem.buttonName}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navigation;
