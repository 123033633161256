import LoginContainer from "@components/Login/containers/LoginContainer";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SubRoutingMainContainer from "./containers/SubRoutingMainContainer";

const RootNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginContainer />} />
        <Route path="*" element={<SubRoutingMainContainer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RootNavigation;
