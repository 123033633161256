import usePopUp from "@hooks/usePopUp";
import SubRoutingMain from "@routes/subRoutes/SubRoutingMain";
import React from "react";

const SubRoutingMainContainer = () => {
  const { popUp } = usePopUp();
  console.log(popUp);
  return <SubRoutingMain popUp={popUp} />;
};

export default SubRoutingMainContainer;
