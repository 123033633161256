import React from "react";
import "./css/create.css";

type Props = {
  onCreateBanner: React.MouseEventHandler<HTMLButtonElement>;
  popupFile: File | null;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setPopupFile: React.Dispatch<React.SetStateAction<File | null>>;
  setExposureFrom: React.Dispatch<React.SetStateAction<number>>;
  setExposureTo: React.Dispatch<React.SetStateAction<number>>;
  setSort: React.Dispatch<React.SetStateAction<number>>;
  setReferenceUrl: React.Dispatch<React.SetStateAction<string>>;
};

const MainBannerCreate = ({
  onCreateBanner,
  popupFile,
  setTitle,
  setPopupFile,
  setExposureFrom,
  setExposureTo,
  setSort,
  setReferenceUrl,
}: Props) => {
  return (
    <div className="main-banner-create-wrap wrap">
      <div className="main-banner-create-container container">
        <div className="greet">
          안녕하세요, <span>Hellen</span>
        </div>
        <div className="main-banner-create-header header">
          <div className="header-left">
            <h2>메인 배너 생성</h2>
            <span>메인 배너를 생성할 수 있습니다.</span>
          </div>
          <div className="header-right">
            <button className="store-banner" onClick={onCreateBanner}>
              저장하기
            </button>
          </div>
        </div>
        <div className="main-banner-create-content perform-content">
          <div className="content-left">
            <div className="option">
              <label htmlFor="title" className="title">
                제목
              </label>
              <input
                type="text"
                id="title"
                placeholder="배너 명 입력"
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="option">
              <label htmlFor="image" className="title">
                이미지
              </label>
              <input type="text" id="image" disabled value={popupFile?.name} />
              <input
                className="blind"
                type="file"
                id="attach-image"
                accept=".jpg, .png"
                onChange={(e) => {
                  if (e.target.files) {
                    setPopupFile(e.target.files[0]);
                  }
                }}
              />
              <label htmlFor="attach-image">첨부하기</label>
            </div>

            <div className="option">
              <label htmlFor="reference-url" className="title">
                레퍼런스 URL
              </label>
              <input
                type="text"
                id="reference-url"
                placeholder="주소 입력"
                onChange={(e) => {
                  setReferenceUrl(e.target.value);
                }}
                disabled={!popupFile}
              />
            </div>
            <div className="option">
              <label htmlFor="date-from" className="title">
                게시시작일
              </label>
              <input
                type="datetime-local"
                id="date-from"
                onChange={(e) => {
                  setExposureFrom(Date.parse(e.target.value));
                }}
              />
              <label htmlFor="date-to" className="title inner">
                게시종료일
              </label>
              <input
                type="datetime-local"
                id="date-to"
                onChange={(e) => {
                  setExposureTo(Date.parse(e.target.value));
                }}
              />
            </div>
            <div className="option">
              <label htmlFor="attached-image-link" className="title">
                우선순위
              </label>
              <input
                type="number"
                id="attached-sort"
                onChange={(e) => {
                  setSort(parseInt(e.target.value));
                }}
              />
            </div>
          </div>
          <div className="content-right">
            <div className="title">미리보기</div>
            <div className="image-preview">
              {popupFile && (
                <img
                  src={URL.createObjectURL(popupFile)}
                  alt="미리보기"
                  className="preview-image"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBannerCreate;
