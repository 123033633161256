import React, { useDebugValue } from "react";
import "./css/index.css";
import { Link } from "react-router-dom";
import { MainBannerType } from "@typedef/components/MainBanner/MainBanner.types";

type Props = {
  selectedId: number;
  mainBannerList: MainBannerType[];
  onUrlClicked: (bannerId: number) => void;
  onRemoveBanner: (bannerId: number) => void;
  onSelectBanner: (bannerId: number) => void;
  onEditBanner: React.MouseEventHandler<HTMLButtonElement>;
};
const MainBanner = ({
  selectedId,
  mainBannerList,
  onUrlClicked,
  onRemoveBanner,
  onSelectBanner,
  onEditBanner,
}: Props) => {
  return (
    <div className="main-banner-wrap wrap">
      <div className="main-banner-container container">
        <div className="greet">
          안녕하세요, <span>Hellen</span>
        </div>
        <div className="main-banner-header header">
          <div className="header-left">
            <h2>메인 배너 관리</h2>
            <span>메인 배너 생성 및 수정을 할 수 있습니다.</span>
          </div>
          <div className="header-right">
            <button
              className="edit-banner"
              disabled={selectedId === 0}
              onClick={onEditBanner}
            >
              수정하기
            </button>
            <button className="create-banner">
              <Link className="create-banner-link" to="/mainbanner/create">
                생성하기
              </Link>
            </button>
          </div>
        </div>
        <ul className="main-banner-list-header list-header">
          <li className="sort-image-preview">이미지 미리보기</li>
          <li className="sort-poster">게시자</li>
          <li className="sort-image-link">이미지 링크</li>
          <li className="sort-post-date">배너 게시 일자</li>
          <li className="sort-exposure-access">노출 여부</li>
          <li className="sort-exposure-rank">노출 순위</li>
          <li className="sort-remove-banner">삭제</li>
        </ul>
        <ul className="main-banner-list-content list-content">
          {mainBannerList.map((item, index) => (
            <li
              className={`main-banner-list-item list-item ${
                item.bannerId === selectedId ? "selected" : ""
              }`}
              key={index}
              onClick={() => onSelectBanner(item.bannerId)}
            >
              <ul>
                <li className="image-preview">
                  <img src={item.bannerFile.files[0].location} />
                </li>
                <li className="poster">{item.title}</li>
                <li className="image-link">
                  <a
                    href={item.bannerFile.files[0].location}
                    onClick={() => onUrlClicked(item.bannerId)}
                  >
                    {item.bannerFile.files[0].location}
                  </a>
                </li>
                <li className="post-date">
                  {item.exposureFrom.substring(0, 10)}
                  {` ~ `}
                  <div></div>
                  {item.exposureTo.substring(0, 10)}
                </li>
                <li className="exposure-access">
                  <div
                    className={`exposure-box ${
                      Date.parse(item.exposureTo) < new Date().getTime()
                        ? "expired"
                        : ""
                    }`}
                  ></div>
                </li>
                <li className="exposure-rank">
                  <div className="rank-box">{item.sort}</div>
                </li>
                <li className="remove-button">
                  <button onClick={() => onRemoveBanner(item.bannerId)}>
                    X
                  </button>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MainBanner;
