import React from "react";

type Props = {
  title: string;
  referenceUrl: string;
  exposureFrom: string;
  exposureTo: string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onPostPopup: React.MouseEventHandler<HTMLButtonElement>;
};
const PostPopup = ({
  title,
  referenceUrl,
  exposureFrom,
  exposureTo,
  onClose,
  onPostPopup,
}: Props) => {
  return (
    <div className="post-popup-wrap">
      <div className="button-box">
        <button className="close-button" onClick={onClose}>
          x
        </button>
      </div>
      <div className="conform-content">
        <div className="title">팝업 명 : {title}</div>
        <div className="url">
          랜딩 url :{" "}
          <a href={`https://${referenceUrl}`} target="_blank">
            {referenceUrl}
          </a>
        </div>
        <div className="exposure-period">
          노출기간 : {exposureFrom.replace("T", " ")} ~{" "}
          {exposureTo.replace("T", " ")}
        </div>
      </div>
      <div className="button-box">
        <button className="post-button" onClick={onPostPopup}>
          팝업전송
        </button>
      </div>
    </div>
  );
};

export default PostPopup;
