import React from "react";

type Props = {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  setReportAnswer: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
};

const ReportAnswer = ({ onClose, setReportAnswer, onSubmit }: Props) => {
  return (
    <div className="report-answer-wrap">
      <div className="button-box">
        <button onClick={onClose}>x</button>
      </div>
      <div>
        <label
          className="title"
          htmlFor="report-answer"
          placeholder="내용을 입력하세요"
        >
          답변하기
        </label>
        <input
          className="content"
          id="report-answer"
          type="textarea"
          onChange={(e) => setReportAnswer(e.target.value)}
        />
      </div>
      <div className="button-box">
        <button onClick={onSubmit}>답변 전송</button>
      </div>
    </div>
  );
};

export default ReportAnswer;
