import { PopupFileType } from "@typedef/components/Popup/Popup.types";
import React from "react";

type Props = {
  onCloseImage: React.MouseEventHandler<HTMLButtonElement>;
  image: PopupFileType;
};

const PopupImage = ({ onCloseImage, image }: Props) => {
  return (
    <div className="popup-image-wrap">
      <div className="button-box">
        <button className="close-button" onClick={onCloseImage}>
          x
        </button>
      </div>
      <div className="image-box">
        <img className="popup-image" src={image.files[0].location} />
      </div>
    </div>
  );
};

export default PopupImage;
