import useAuth from "@hooks/Auth/useAuth";
import usePopUp from "@hooks/usePopUp";
import { apiOrigin, apiRoute, requestPost } from "@lib/api/api";
import { BasicAPIResponseType } from "@typedef/API.types";
import { ReportAnswerType } from "@typedef/components/Report/report.types";
import React, { useCallback, useState } from "react";
import ReportAnswer from "../components/ReportAnswer";

type Props = {
  uid: number;
};

const ReportAnswerContainer = ({ uid }: Props) => {
  const { token } = useAuth();
  const { __hidePopUpFromHooks } = usePopUp();
  const [reportAnswer, setReportAnswer] = useState("");

  const onClose = useCallback(() => {
    __hidePopUpFromHooks();
  }, [__hidePopUpFromHooks]);

  // 답변 전송 버튼
  const onSubmit = useCallback(async () => {
    const { data } = await requestPost<BasicAPIResponseType<ReportAnswerType>>(
      `${apiOrigin}${apiRoute.report}/${uid}`,
      {
        Authorization: `Bearer ${token}`,
      },
      {
        answer: reportAnswer,
      }
    );
    console.log(uid, data);
  }, [uid]);

  return (
    <ReportAnswer
      onClose={onClose}
      onSubmit={onSubmit}
      setReportAnswer={setReportAnswer}
    />
  );
};

export default ReportAnswerContainer;
