import React from "react";
import "./css/index.css";

type Props = {
  onLoginSubmitted: () => void;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  password: string;
};

const Login = ({
  onLoginSubmitted,
  setId,
  setPassword,
  id,
  password,
}: Props) => {
  return (
    <div className="login-wrap">
      <div className="login-container">
        <h1>크누마켓 관리자</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onLoginSubmitted();
          }}
        >
          <input
            className="login-input"
            type="text"
            name="userName"
            id="idInput"
            onChange={(e) => setId(e.target.value)}
            value={id}
            placeholder="ID"
          />
          <input
            className="login-input"
            type="password"
            name="password"
            id="passwordInput"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="PASSWORD"
          />
          <button className="login-btn">로그인</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
