import useAuth from "@hooks/Auth/useAuth";
import usePopUp from "@hooks/usePopUp";
import { apiOrigin, apiRoute, requestFormPut, requestGet } from "@lib/api/api";
import { BasicAPIResponseType } from "@typedef/API.types";
import {
  PopupResponseType,
  PopupType,
} from "@typedef/components/Popup/Popup.types";
import React, { useCallback, useEffect, useState } from "react";
import Popup from "../Popup";
import CanclePopupContainer from "./CanclePopupContainer";
import PopupImageContanier from "./PopupImageContainer";

const PopupContainer = () => {
  const threshold = 0.7;
  const { token } = useAuth();
  const { __showPopUpFromHooks } = usePopUp();
  const [popupList, setPopupList] = useState<PopupType[]>([]);
  const [selectedPopup, setSelectedPopup] = useState<PopupType>({
    popupId: 0,
    title: "",
    referenceUrl: "",
    viewCount: 0,
    exposureFrom: "",
    exposureTo: "",
    popupFile: null,
  });
  const [selectedId, setSelectedId] = useState(0);

  //팝업 리스트 가져오기
  const getPopupList = useCallback(async () => {
    const { data } = await requestGet<BasicAPIResponseType<PopupResponseType>>(
      `${apiOrigin}${apiRoute.popuplist}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    const list = data.rows;
    setPopupList(list);
  }, [token, popupList]);

  const onPopupListEndReached = useCallback(async () => {
    const lastId = popupList[popupList.length - 1].popupId;

    const { data } = await requestGet<BasicAPIResponseType<PopupResponseType>>(
      `${apiOrigin}${apiRoute.popuplist}?popupId=${lastId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    const addList = data.rows;
    setTimeout(() => {
      setPopupList([...popupList, ...addList]);
    }, 1000);
  }, [popupList]);

  // 팝업 클릭
  const onPopupClicked = useCallback(
    (index: number) => {
      const selectedItem = popupList[index];
      setSelectedPopup(selectedItem);
      console.log(selectedItem);
    },
    [selectedPopup]
  );

  // 이미지 보기 버튼
  const onFloatImage = useCallback(async () => {
    if (selectedPopup.popupFile) {
      const imageUrl = selectedPopup.popupFile;
      __showPopUpFromHooks(<PopupImageContanier image={imageUrl} />);
    }
  }, [selectedPopup]);

  // Url 클릭
  const onUrlClicked = useCallback(async () => {
    const { data } = await requestGet<BasicAPIResponseType<"">>(
      `${apiOrigin}${apiRoute.popups}/${selectedPopup.popupId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log(data);
  }, [selectedPopup]);

  // 팝업 취소 버튼
  const onCanclePopup = useCallback(async () => {
    __showPopUpFromHooks(
      <CanclePopupContainer selectedPopup={selectedPopup} />
    );
  }, [selectedPopup]);

  useEffect(() => {
    getPopupList();
  }, []);

  return (
    <Popup
      selectedId={selectedId}
      popupList={popupList}
      onPopupClicked={onPopupClicked}
      selectedPopup={selectedPopup}
      threshold={threshold}
      onPopupListEndReached={onPopupListEndReached}
      onFloatImage={onFloatImage}
      onUrlClicked={onUrlClicked}
      onCanclePopup={onCanclePopup}
    />
  );
};

export default PopupContainer;
