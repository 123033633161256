import useAuth from "@hooks/Auth/useAuth";
import usePopUp from "@hooks/usePopUp";
import { apiOrigin, apiRoute, requestFormPost } from "@lib/api/api";
import { BasicAPIResponseType } from "@typedef/API.types";
import { PopupPostType } from "@typedef/components/Post/post.types";
import React, { useCallback } from "react";
import PostPopup from "../components/PostPopup";

type Props = {
  title: string;
  popupFile: File | undefined;
  referenceUrl: string;
  exposureFrom: string;
  exposureTo: string;
};

const PostPopupContainer = ({
  title,
  popupFile,
  referenceUrl,
  exposureFrom,
  exposureTo,
}: Props) => {
  const { __hidePopUpFromHooks } = usePopUp();
  const { token } = useAuth();

  const onClose = useCallback(() => {
    __hidePopUpFromHooks();
  }, [__hidePopUpFromHooks]);

  const onPostPopup = useCallback(async () => {
    const formData = new window.FormData();
    formData.append("title", title);
    if (popupFile) {
      formData.append("popupFile", popupFile);
    }
    formData.append("referenceUrl", referenceUrl);
    formData.append("exposureFrom", exposureFrom.toString());
    formData.append("exposureTo", exposureTo.toString());

    const { data } = await requestFormPost<BasicAPIResponseType<PopupPostType>>(
      `${apiOrigin}${apiRoute.popups}`,
      {
        Authorization: `Bearer ${token}`,
      },
      formData
    );
    __hidePopUpFromHooks();

    alert("팝업이 전송되었습니다.");
  }, [
    title,
    popupFile,
    referenceUrl,
    exposureFrom,
    exposureTo,
    __hidePopUpFromHooks,
  ]);

  return (
    <PostPopup
      onClose={onClose}
      onPostPopup={onPostPopup}
      title={title}
      referenceUrl={referenceUrl}
      exposureFrom={exposureFrom}
      exposureTo={exposureTo}
    />
  );
};

export default PostPopupContainer;
