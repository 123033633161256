import React, { useState } from "react";
import "./css/index.css";

type Props = {
  onPostPopup: React.MouseEventHandler<HTMLButtonElement>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setPopupFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setReferenceUrl: React.Dispatch<React.SetStateAction<string>>;
  setExposureFrom: React.Dispatch<React.SetStateAction<string>>;
  setExposureTo: React.Dispatch<React.SetStateAction<string>>;
  popupFile: File | undefined;
};
const Post = ({
  onPostPopup,
  setTitle,
  setPopupFile,
  setReferenceUrl,
  setExposureFrom,
  setExposureTo,
  popupFile,
}: Props) => {
  return (
    <div className="post-wrap">
      <div className="post-container container">
        <div className="greet">
          안녕하세요, <span>Hellen</span>
        </div>
        <div className="main-banner-header header">
          <div className="header-left">
            <h2>팝업 전송</h2>
            <span>팝업을 전송할 수 있습니다.</span>
          </div>
          <div className="header-right">
            <button onClick={onPostPopup} className="submit-button">
              팝업 전송
            </button>
          </div>
        </div>

        <div className="post-content perform-content">
          <div className="content-left">
            <form
              name="postPopup"
              className="form-list"
              encType="multipart/form-data"
            >
              <div className="option">
                <label htmlFor="title" className="title">
                  팝업 명
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="팝업 명 입력"
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="option">
                <label htmlFor="image" className="title">
                  이미지
                </label>
                <input
                  type="text"
                  id="image"
                  disabled
                  value={popupFile?.name}
                />

                <input
                  className="blind"
                  type="file"
                  id="attach-image"
                  name="media"
                  accept="image/*"
                  onChange={(e) => {
                    if (!e.target.files) return;
                    setPopupFile(e.target.files[0]);
                  }}
                  required
                />
                <label htmlFor="attach-image">첨부하기</label>
              </div>
              <div className="option">
                <label htmlFor="reference-url" className="title">
                  레퍼런스 URL
                </label>
                <input
                  type="text"
                  id="reference-url"
                  placeholder="주소 입력"
                  onChange={(e) => setReferenceUrl(e.target.value)}
                  disabled={!popupFile}
                />
              </div>
              <div className="option">
                <label htmlFor="date-from" className="title">
                  게시시작일
                </label>
                <input
                  type="datetime-local"
                  id="date-from"
                  required
                  onChange={(e) => {
                    const date = e.target.value;
                    setExposureFrom(date);
                  }}
                />
                <label htmlFor="date-to" className="title inner">
                  게시종료일
                </label>
                <input
                  type="datetime-local"
                  id="date-to"
                  required
                  onChange={(e) => {
                    const date = e.target.value;
                    setExposureTo(date);
                  }}
                />
              </div>
            </form>
          </div>
          <div className="content-right">
            <div className="title">미리보기</div>
            <div className="image-preview">
              {popupFile && (
                <img
                  className="preview-image"
                  src={URL.createObjectURL(popupFile)}
                  alt="미리보기"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
