import React from "react";
import "./css/edit.css";

type Props = {
  title: string;
  exposureFrom: string;
  exposureTo: string;
  sort: number;
  referenceUrl: string;
  image: {
    fileForderId: string;
    files: {
      location: string;
    }[];
  };
  onStoreBanner: React.MouseEventHandler<HTMLButtonElement>;
  updatedFile: File | null;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setUpdatedFile: React.Dispatch<React.SetStateAction<File | null>>;
  setExposureFrom: React.Dispatch<React.SetStateAction<string>>;
  setExposureTo: React.Dispatch<React.SetStateAction<string>>;
  setSort: React.Dispatch<React.SetStateAction<number>>;
  setReferenceUrl: React.Dispatch<React.SetStateAction<string>>;
};

const MainBannerEdit = ({
  title,
  exposureFrom,
  exposureTo,
  sort,
  image,
  referenceUrl,
  onStoreBanner,
  updatedFile,
  setTitle,
  setUpdatedFile,
  setExposureFrom,
  setExposureTo,
  setSort,
  setReferenceUrl,
}: Props) => {
  return (
    <div className="main-banner-edit-wrap wrap">
      <div className="main-banner-edit-container container">
        <div className="greet">
          안녕하세요, <span>Hellen</span>
        </div>
        <div className="main-banner-edit-header header">
          <div className="header-left">
            <h2>메인 배너 수정</h2>
            <span>메인 배너를 수정할 수 있습니다.</span>
          </div>
          <div className="header-right">
            <button className="store-banner" onClick={onStoreBanner}>
              저장하기
            </button>
          </div>
        </div>
        <div className="main-banner-edit-content perform-content">
          <div className="content-left">
            <div className="option">
              <label htmlFor="title" className="title">
                제목
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="option">
              <label htmlFor="image" className="title">
                이미지
              </label>
              <input
                type="text"
                id="image"
                disabled
                value={updatedFile ? updatedFile.name : image.files[0].location}
              />
              <input
                className="blind"
                type="file"
                id="attach-image"
                accept=".jpg, .png"
                onChange={(e) => {
                  if (e.target.files) {
                    setUpdatedFile(e.target.files[0]);
                  }
                }}
              />
              <label htmlFor="attach-image">첨부하기</label>
            </div>

            <div className="option">
              <label htmlFor="reference-url" className="title">
                레퍼런스 URL
              </label>
              <input
                type="text"
                id="reference-url"
                onChange={(e) => {
                  setReferenceUrl(e.target.value);
                }}
                disabled={!updatedFile}
                value={referenceUrl}
              />
            </div>
            <div className="option">
              <label htmlFor="exposure-from" className="title">
                게시시작일
              </label>
              <input
                type="datetime-local"
                id="exposure-from"
                value={exposureFrom}
                onChange={(e) => {
                  setExposureFrom(e.target.value);
                  console.log(e.target.value);
                }}
              />
              <label htmlFor="exposure-to" className="title inner">
                게시종료일
              </label>
              <input
                type="datetime-local"
                id="exposure-to"
                value={exposureTo}
                onChange={(e) => {
                  setExposureTo(e.target.value);
                }}
              />
            </div>
            <div className="option">
              <label htmlFor="image-link" className="title">
                우선순위
              </label>
              <input
                type="number"
                id="sort"
                value={sort}
                onChange={(e) => {
                  setSort(parseInt(e.target.value));
                }}
              />
            </div>
          </div>
          <div className="content-right">
            <div className="title">미리보기</div>
            <div className="image-preview">
              <img
                src={
                  updatedFile
                    ? URL.createObjectURL(updatedFile)
                    : image.files[0].location
                }
                alt="미리보기"
                className="preview-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBannerEdit;
