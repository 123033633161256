import useAuth from "@hooks/Auth/useAuth";
import usePopUp from "@hooks/usePopUp";
import {
  apiOrigin,
  apiRoute,
  requestFormPatch,
  requestFormPut,
} from "@lib/api/api";
import { BasicAPIResponseType } from "@typedef/API.types";
import {
  PopupCancleType,
  PopupType,
} from "@typedef/components/Popup/Popup.types";
import React, { useCallback } from "react";
import CanclePopup from "../components/CanclePopup";

type Props = {
  selectedPopup: PopupType;
};

const CanclePopupContainer = ({ selectedPopup }: Props) => {
  const { token } = useAuth();
  const { __hidePopUpFromHooks } = usePopUp();

  const onClose = useCallback(() => {
    __hidePopUpFromHooks();
  }, [__hidePopUpFromHooks]);

  const onCanclePopup = useCallback(async () => {
    const formData = new FormData();
    formData.append("title", selectedPopup.title);
    formData.append("referenceUrl", selectedPopup.referenceUrl);
    formData.append("exposureFrom", selectedPopup.exposureFrom);
    formData.append("exposureTo", selectedPopup.exposureTo);
    if (selectedPopup.popupFile) {
      formData.append("popupFile", selectedPopup.popupFile.files[0].location);
    }

    const { data } = await requestFormPatch<
      BasicAPIResponseType<PopupCancleType>
    >(
      `${apiOrigin}${apiRoute.popups}/${selectedPopup.popupId}`,
      {
        Authorization: `Bearer ${token}`,
      },
      formData
    );

    __hidePopUpFromHooks();

    alert("팝업이 취소되었습니다.");
  }, []);

  return (
    <CanclePopup
      onClose={onClose}
      onCanclePopup={onCanclePopup}
      selectedPopup={selectedPopup}
    />
  );
};

export default CanclePopupContainer;
