import usePopUp from "@hooks/usePopUp";
import React, { useCallback, useEffect, useState } from "react";
import Post from "../Post";
import PostPopupContainer from "./PostPopupContainer";

const PostContainer = () => {
  const { __showPopUpFromHooks } = usePopUp();
  const [title, setTitle] = useState<string>("");
  const [referenceUrl, setReferenceUrl] = useState<string>("");
  const [exposureFrom, setExposureFrom] = useState<string>("");
  const [exposureTo, setExposureTo] = useState<string>("");
  const [popupFile, setPopupFile] = useState<File | undefined>();

  // Formdata 저장하기
  const onPostPopup = useCallback(() => {
    __showPopUpFromHooks(
      <PostPopupContainer
        title={title}
        popupFile={popupFile}
        referenceUrl={referenceUrl}
        exposureFrom={exposureFrom}
        exposureTo={exposureTo}
      />
    );
  }, [title, popupFile, referenceUrl, exposureFrom, exposureTo]);

  return (
    <Post
      onPostPopup={onPostPopup}
      setTitle={setTitle}
      setPopupFile={setPopupFile}
      setReferenceUrl={setReferenceUrl}
      setExposureFrom={setExposureFrom}
      setExposureTo={setExposureTo}
      popupFile={popupFile}
    />
  );
};

export default PostContainer;
