import React from "react";
import Navigation from "../Navigation";

const NavigationButtonDataList = [
  {
    name: "post",
    link: "/post",
    buttonName: "팝업 전송",
  },
  {
    name: "popup",
    link: "/popup",
    buttonName: "팝업 내역",
  },
  {
    name: "report",
    link: "/report",
    buttonName: "CS 문의",
  },
  {
    name: "mainbanner",
    link: "/mainbanner/list",
    buttonName: "메인 배너 관리",
  },
];

const NavigationContainer = () => {
  return <Navigation NavigationButtonDataList={NavigationButtonDataList} />;
};

export default NavigationContainer;
