import RootNavigationContainer from "@routes/containers/RootNavigationContainer";
import rootReducer from "@store/rootReducer";
import React from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";

const store = createStore(rootReducer);

const App = () => {
  return (
    <Provider store={store}>
      <RootNavigationContainer />
    </Provider>
  );
};

export default App;
