import React from "react";
import NavigationContainer from "@components/Navigation/containers/NavigationContainer";
import { Routes, Route } from "react-router-dom";
import PostContainer from "@components/Post/containers/PostContainer";
import MainBannerContainer from "@components/MainBanner/containers/MainBannerContainer";
import MainBannerCreateContainer from "@components/MainBanner/containers/MainBannerCreateContainer";
import PopupContainer from "@components/Popup/containers/PopupContainer";
import ReportContainer from "@components/Report/containers/ReportContainer";
import PopUp from "@components/common/PopUp/PopUp";
import { PopUpTypes } from "@typedef/components/common/common.types";
import MainBannerEditContainer from "@components/MainBanner/containers/MainBannerEditContainer";

type Props = {
  popUp: PopUpTypes;
};

const SubRoutingMain = ({ popUp }: Props) => {
  return (
    <div style={{ display: "flex" }}>
      <NavigationContainer />
      <Routes>
        <Route path="/post" element={<PostContainer />} />
        <Route path="/popup" element={<PopupContainer />} />
        <Route path="/report" element={<ReportContainer />} />
        <Route path="/mainbanner">
          <Route path="list" element={<MainBannerContainer />} />
          <Route path="create" element={<MainBannerCreateContainer />} />
          <Route path="edit/:bannerId" element={<MainBannerEditContainer />} />
        </Route>
      </Routes>
      {popUp.isShown && <PopUp child={popUp.popUp} />}
    </div>
  );
};

export default SubRoutingMain;
