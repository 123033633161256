import { PopupType } from "@typedef/components/Popup/Popup.types";
import React from "react";

type Props = {
  onCanclePopup: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  selectedPopup: PopupType;
};

const CanclePopup = ({ onCanclePopup, onClose, selectedPopup }: Props) => {
  return (
    <div className="cancle-popup-wrap">
      <div className="button-box">
        <button onClick={onClose}>x</button>
      </div>
      <div className="content">
        <div>팝업명: {selectedPopup.title}</div>
        <span>아래 버튼을 누르면 현재 전송된 팝업이 취소됩니다.</span>
      </div>
      <div className="bottom-button-box">
        <button onClick={onCanclePopup}>팝업 취소하기</button>
      </div>
    </div>
  );
};

export default CanclePopup;
